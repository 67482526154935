.Header-logo-conatiner {
  display: flex;
  align-items: center;
}

.Header-logo-image {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 600px) {
  .Header-logo-conatiner {
    display: flex;
    align-items: center;
    margin-left: 10vh;
  }
}
