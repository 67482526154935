:root {
  --primary: #123456;
  --secondary: black;
}

.About-me-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.About-me-header {
  font-size: 12vh;
  color: var(--primary);
  text-align: start;
  font-family: "Baloo Bhaina 2", cursive;
}

.About-me-subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
  text-align: start;
  font-family: "Baloo Bhaina 2", cursive;
  height: 15vh;
  font-weight: bold;
}

.About-me-body-container {
  font-size: 2.5vh;
  color: black;
  text-align: start;
  font-family: "Aleo", serif;
}

.About-me-subheader-your {
  font-size: 5vh;
}

.About-me-subheader-italic {
  font-style: italic;
  font-size: 2.5vh;
}

@media (min-width: 900px) {
  .About-me-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .About-me-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 5vh;
    color: var(--primary);
    text-align: start;
    font-family: "Baloo Bhaina 2", cursive;
    height: 6vh;
    font-weight: bold;
  }

  .About-me-subheader-italic {
    font-style: italic;
    font-size: 5vh;
  }

  .About-me-subheader-your {
    font-size: 5vh;
  }
}
