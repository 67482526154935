:root {
  --primary: #123456;
}

.Portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--primary);
}

.Portoflio-grid-container {
  align-content: center;
  width: 80%;
}

.Portfolio-header {
  font-size: 8vh;
  color: white;
  margin: 4vh;
  border-bottom-color: white;
  border-bottom-width: 0.5vh;
  border-bottom-style: solid;
  font-family: "Baloo Bhaina 2", cursive;
}

.Portfolio-grid {
  display: grid;
  grid-template-columns: repeat(1, 95%);
  justify-content: space-evenly;
  align-items: center;
}

@media (min-width: 600px) {
  .Portfolio-header {
    font-size: 8vh;
    color: white;
    margin: 4vh;
    height: 10vh;
    border-bottom-color: white;
    border-bottom-width: 0.5vh;
    border-bottom-style: solid;
    font-family: "Baloo Bhaina 2", cursive;
  }

  .Portfolio-grid {
    display: grid;
    grid-template-columns: repeat(3, 50vh);
    justify-content: space-evenly;
    align-items: center;
  }
}
