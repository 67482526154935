.Portfolio-item-container {
  display: flex;
  width: 98%;
  height: 98%;
}

.Portfolio-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Portfolio-info-container {
  display: flex;
  flex-direction: column;
  width: 74%;
  background-color: rgba(0, 0, 0, 0.534);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  margin-top: 5vh;
  z-index: 1;
  opacity: 0;
  transition: 1s;
}

.Portfolio-title {
  font-size: 6vh;
  font-family: "Aleo", serif;
}

.Portfolio-summary {
  font-size: 4vh;
  font-family: "Aleo", serif;
}

.Portfolio-item-container:hover .Portfolio-info-container {
  opacity: 1;
  z-index: 2;
}

@media (min-width: 600px) {
  .Portfolio-info-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: rgba(0, 0, 0, 0.534);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    margin-top: 5vh;
    margin-left: 5vh;
    z-index: 1;
    opacity: 0;
    transition: 1s;
  }
}
