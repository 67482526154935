:root {
  --primary: #123456;
}

.Contact-container {
  display: flex;
  flex-direction: column;
}

.Contact-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.Contact-header {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  font-size: 7vh;
  font-weight: bold;
  color: var(--primary);
  font-family: "Baloo Bhaina 2", cursive;
}

.Contact-body {
  width: 80%;
  font-size: 3vh;
  text-align: left;
  font-family: "Aleo", serif;
  margin-bottom: 2.5vh;
}

.Contact-body-bold {
  width: 80%;
  font-size: 3.5vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: bold;
  text-align: left;
  height: 3vh;
}

@media (min-width: 600px) {
  .Contact-body {
    width: 60%;
    font-size: 3vh;
    text-align: left;
    font-family: "Aleo", serif;
    margin-bottom: 3vh;
  }

  .Contact-body-bold {
    width: 60%;
    font-size: 3.5vh;
    font-family: "Baloo Bhaina 2", cursive;
    font-weight: bold;
    text-align: left;
    height: 4vh;
  }
}
