#item1 {
  grid-area: item1;
}
#item2 {
  grid-area: item2;
}
#item3 {
  grid-area: item3;
}
#item4 {
  grid-area: item4;
}
#item5 {
  grid-area: item5;
}

.Footer-container {
  height: 26vh;
}

.Footer-top {
  height: 50%;
  background-color: var(--alternate);
  display: grid;
  flex-direction: row;
  grid-template-areas:
    "item1 item2 item3"
    "item4 item4 item5";
  justify-content: flex-start;
  align-items: center;
}

.Footer-links-item {
  font-size: 3vh;
  color: white;
  margin-left: 3vh;
  font-family: "Aleo", serif;
  text-align: left;
}

.Footer-bottom {
  height: 50%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Footer-bottom-left {
  font-size: 1.8vh;
  color: white;
  font-weight: bold;
}

.Footer-bottom-right {
  font-size: 1.8vh;
  color: white;
  font-weight: bold;
}

@media (min-width: 600px) {
  .Footer-container {
    height: 16vh;
  }

  .Footer-top {
    height: 55%;
    background-color: var(--alternate);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .Footer-links-item {
    font-size: 3vh;
    color: white;
    margin-left: 3vh;
    font-family: "Aleo", serif;
  }

  .Footer-bottom {
    height: 45%;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Footer-bottom-left {
    text-align: left;
    margin-left: 3vh;
    font-size: 2vh;
    color: white;
    font-weight: bold;
  }

  .Footer-bottom-right {
    text-align: right;
    margin-right: 3vh;
    font-size: 2vh;
    color: white;
    font-weight: bold;
  }
}
