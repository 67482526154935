.Reviews-container {
  display: flex;
  flex-direction: column;
}

.Reviews-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Reviews-header-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reviews-subheader {
  width: 80%;
  /* font-size: 7vh; */
  /* margin: 2vh; */
  text-align: left;
}

.Reviews-header-item {
  background-color: var(--primary);
  color: white;
  font-size: 6vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: bold;
  width: 100%;
  height: 12vh;
  margin-bottom: 2vh;
}
