.Screen-breaker {
  width: 70%;
  height: 3vh;
  background: repeating-linear-gradient(
    45deg,
    var(--primary),
    var(--primary) 2px,
    #ffffff 2px,
    #ffffff 10px
  );
  opacity: 0.3;
  margin-top: 3vh;
  margin-bottom: 3vh;
}
