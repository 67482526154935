:root {
  --primary: #123456;
}

.Header-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.Header-top {
  background: repeating-linear-gradient(
    45deg,
    var(--primary),
    var(--primary) 2px,
    #ffffff 2px,
    #ffffff 2.3px
  );
  width: 100%;
  border-bottom: thin;
  border-bottom-style: solid;
  border-bottom-color: var(--primary);
}

.Header-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vh;
  height: 12vh;
  border-bottom-color: rgba(0, 0, 0, 0.13);
  border-bottom-width: thin;
  border-bottom-style: solid;
}

@media (min-width: 600px) {
  .Header-conatiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12vh;
  }

  .Header-top {
    background: repeating-linear-gradient(
      45deg,
      var(--primary),
      var(--primary) 2px,
      #ffffff 2px,
      #ffffff 2.3px
    );
    width: 100%;
    height: 5vh;
    border-bottom: thin;
    border-bottom-style: solid;
    border-bottom-color: var(--primary);
  }

  .Header-body-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2vh;
    height: 20vh;
    border-bottom-color: rgba(0, 0, 0, 0.13);
    border-bottom-width: thin;
    border-bottom-style: solid;
  }
}
