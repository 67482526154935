.Review-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Review-item-info-summary {
  width: 90%;
  text-align: left;
  font-size: 2.5vh;
  font-family: "Aleo", serif;
}

.Review-item-info-header {
  width: 90%;
  text-align: right;
  font-size: 3vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-style: italic;
}

@media (min-width: 600px) {
  .Review-item-info-summary {
    width: 80%;
    text-align: left;
    font-size: 3vh;
    font-family: "Aleo", serif;
  }

  .Review-item-info-header {
    width: 80%;
    text-align: right;
    font-size: 4vh;
    font-family: "Baloo Bhaina 2", cursive;
  }
}
