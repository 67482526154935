.Services-container {
  display: flex;
  flex-direction: column;
}

.Services-about-me-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Services-about-me-header {
  font-size: 5vh;
  color: var(--primary);
  text-align: start;
  width: 80%;
  font-family: "Baloo Bhaina 2", cursive;
}

.Services-about-me-summary {
  font-size: 3vh;
  text-align: start;
  width: 80%;
  font-family: "Aleo", serif;
  margin-bottom: 4vh;
}

.Services-header-item {
  background-color: var(--primary);
  color: white;
  font-size: 6vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: bold;
  width: 100%;
  height: 12vh;
  margin-bottom: 2vh;
}
