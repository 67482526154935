.Service-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Service-item-info {
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: 80%;
  text-align: start;
  font-family: "Aleo", serif;
}

.Service-item-info-header {
  font-size: 4vh;
}

.Service-item-info-summary {
  font-size: 3vh;
  margin-left: 6vh;
}

.Service-item-images-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.Service-item-header-contianer {
  display: flex;
  flex-direction: row;
}

.Service-image-item-container {
  padding: 1vh;
}

.Service-image-item {
  object-fit: cover;
  height: 50vh;
  width: 100%;
  background-repeat: no-repeat;
}

.Service-icon {
  margin-right: 2vh;
}

#Item-0 {
  background-color: var(--primary);
  color: white;
}
