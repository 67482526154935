@import url(https://fonts.googleapis.com/css2?family=Karla&display=swap);
@import url(https://fonts.googleapis.com/css?family=Aleo|Baloo+Bhaina+2&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Home-container {
  display: flex;
  flex-direction: column;
}

.Home-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:root {
  --primary: #123456;
}

.Header-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.Header-top {
  background: repeating-linear-gradient(
    45deg,
    #123456,
    #123456 2px,
    #ffffff 2px,
    #ffffff 2.3px
  );
  background: repeating-linear-gradient(
    45deg,
    var(--primary),
    var(--primary) 2px,
    #ffffff 2px,
    #ffffff 2.3px
  );
  width: 100%;
  border-bottom: thin;
  border-bottom-style: solid;
  border-bottom-color: #123456;
  border-bottom-color: var(--primary);
}

.Header-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vh;
  height: 12vh;
  border-bottom-color: rgba(0, 0, 0, 0.13);
  border-bottom-width: thin;
  border-bottom-style: solid;
}

@media (min-width: 600px) {
  .Header-conatiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12vh;
  }

  .Header-top {
    background: repeating-linear-gradient(
      45deg,
      #123456,
      #123456 2px,
      #ffffff 2px,
      #ffffff 2.3px
    );
    background: repeating-linear-gradient(
      45deg,
      var(--primary),
      var(--primary) 2px,
      #ffffff 2px,
      #ffffff 2.3px
    );
    width: 100%;
    height: 5vh;
    border-bottom: thin;
    border-bottom-style: solid;
    border-bottom-color: #123456;
    border-bottom-color: var(--primary);
  }

  .Header-body-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2vh;
    height: 20vh;
    border-bottom-color: rgba(0, 0, 0, 0.13);
    border-bottom-width: thin;
    border-bottom-style: solid;
  }
}

.Header-logo-conatiner {
  display: flex;
  align-items: center;
}

.Header-logo-image {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 600px) {
  .Header-logo-conatiner {
    display: flex;
    align-items: center;
    margin-left: 10vh;
  }
}

:root {
  --secondary: #123456;
}

#item1 {
  grid-area: item1;
}
#item2 {
  grid-area: item2;
}
#item3 {
  grid-area: item3;
}
#item4 {
  grid-area: item4;
}
#item5 {
  grid-area: item5;
}

.Header-links-container {
  display: grid;
  grid-template-areas:
    "item1 item2 item3"
    "item4 item4 item5";
  justify-content: space-around;
  align-items: center;
}

.Header-links-item {
  text-align: left;
  font-size: 2.5vh;
  color: white;
  font-weight: bolder;
  font-family: "Baloo Bhaina 2", cursive;
}

@media (min-width: 600px) {
  .Header-links-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8vh;
    height: 8vh;
  }

  .Header-links-item {
    margin-right: 2vh;
    font-size: 2.5vh;
    color: white;
    font-weight: bolder;
    font-family: "Baloo Bhaina 2", cursive;
  }
}

#item1 {
  grid-area: item1;
}
#item2 {
  grid-area: item2;
}
#item3 {
  grid-area: item3;
}
#item4 {
  grid-area: item4;
}
#item5 {
  grid-area: item5;
}

.Footer-container {
  height: 26vh;
}

.Footer-top {
  height: 50%;
  background-color: var(--alternate);
  display: grid;
  flex-direction: row;
  grid-template-areas:
    "item1 item2 item3"
    "item4 item4 item5";
  justify-content: flex-start;
  align-items: center;
}

.Footer-links-item {
  font-size: 3vh;
  color: white;
  margin-left: 3vh;
  font-family: "Aleo", serif;
  text-align: left;
}

.Footer-bottom {
  height: 50%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Footer-bottom-left {
  font-size: 1.8vh;
  color: white;
  font-weight: bold;
}

.Footer-bottom-right {
  font-size: 1.8vh;
  color: white;
  font-weight: bold;
}

@media (min-width: 600px) {
  .Footer-container {
    height: 16vh;
  }

  .Footer-top {
    height: 55%;
    background-color: var(--alternate);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .Footer-links-item {
    font-size: 3vh;
    color: white;
    margin-left: 3vh;
    font-family: "Aleo", serif;
  }

  .Footer-bottom {
    height: 45%;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Footer-bottom-left {
    text-align: left;
    margin-left: 3vh;
    font-size: 2vh;
    color: white;
    font-weight: bold;
  }

  .Footer-bottom-right {
    text-align: right;
    margin-right: 3vh;
    font-size: 2vh;
    color: white;
    font-weight: bold;
  }
}

:root {
  --primary: #123456;
  --secondary: black;
}

.About-me-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.About-me-header {
  font-size: 12vh;
  color: #123456;
  color: var(--primary);
  text-align: start;
  font-family: "Baloo Bhaina 2", cursive;
}

.About-me-subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #123456;
  color: var(--primary);
  text-align: start;
  font-family: "Baloo Bhaina 2", cursive;
  height: 15vh;
  font-weight: bold;
}

.About-me-body-container {
  font-size: 2.5vh;
  color: black;
  text-align: start;
  font-family: "Aleo", serif;
}

.About-me-subheader-your {
  font-size: 5vh;
}

.About-me-subheader-italic {
  font-style: italic;
  font-size: 2.5vh;
}

@media (min-width: 900px) {
  .About-me-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .About-me-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 5vh;
    color: #123456;
    color: var(--primary);
    text-align: start;
    font-family: "Baloo Bhaina 2", cursive;
    height: 6vh;
    font-weight: bold;
  }

  .About-me-subheader-italic {
    font-style: italic;
    font-size: 5vh;
  }

  .About-me-subheader-your {
    font-size: 5vh;
  }
}

.Portfolio-item-container {
  display: flex;
  width: 98%;
  height: 98%;
}

.Portfolio-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Portfolio-info-container {
  display: flex;
  flex-direction: column;
  width: 74%;
  background-color: rgba(0, 0, 0, 0.534);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  margin-top: 5vh;
  z-index: 1;
  opacity: 0;
  transition: 1s;
}

.Portfolio-title {
  font-size: 6vh;
  font-family: "Aleo", serif;
}

.Portfolio-summary {
  font-size: 4vh;
  font-family: "Aleo", serif;
}

.Portfolio-item-container:hover .Portfolio-info-container {
  opacity: 1;
  z-index: 2;
}

@media (min-width: 600px) {
  .Portfolio-info-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: rgba(0, 0, 0, 0.534);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    margin-top: 5vh;
    margin-left: 5vh;
    z-index: 1;
    opacity: 0;
    transition: 1s;
  }
}

:root {
  --primary: #123456;
}

.Portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #123456;
  background-color: var(--primary);
}

.Portoflio-grid-container {
  align-content: center;
  width: 80%;
}

.Portfolio-header {
  font-size: 8vh;
  color: white;
  margin: 4vh;
  border-bottom-color: white;
  border-bottom-width: 0.5vh;
  border-bottom-style: solid;
  font-family: "Baloo Bhaina 2", cursive;
}

.Portfolio-grid {
  display: grid;
  grid-template-columns: repeat(1, 95%);
  justify-content: space-evenly;
  align-items: center;
}

@media (min-width: 600px) {
  .Portfolio-header {
    font-size: 8vh;
    color: white;
    margin: 4vh;
    height: 10vh;
    border-bottom-color: white;
    border-bottom-width: 0.5vh;
    border-bottom-style: solid;
    font-family: "Baloo Bhaina 2", cursive;
  }

  .Portfolio-grid {
    display: grid;
    grid-template-columns: repeat(3, 50vh);
    justify-content: space-evenly;
    align-items: center;
  }
}

.Screen-breaker {
  width: 70%;
  height: 3vh;
  background: repeating-linear-gradient(
    45deg,
    var(--primary),
    var(--primary) 2px,
    #ffffff 2px,
    #ffffff 10px
  );
  opacity: 0.3;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.Services-container {
  display: flex;
  flex-direction: column;
}

.Services-about-me-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Services-about-me-header {
  font-size: 5vh;
  color: var(--primary);
  text-align: start;
  width: 80%;
  font-family: "Baloo Bhaina 2", cursive;
}

.Services-about-me-summary {
  font-size: 3vh;
  text-align: start;
  width: 80%;
  font-family: "Aleo", serif;
  margin-bottom: 4vh;
}

.Services-header-item {
  background-color: var(--primary);
  color: white;
  font-size: 6vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: bold;
  width: 100%;
  height: 12vh;
  margin-bottom: 2vh;
}

.Service-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Service-item-info {
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: 80%;
  text-align: start;
  font-family: "Aleo", serif;
}

.Service-item-info-header {
  font-size: 4vh;
}

.Service-item-info-summary {
  font-size: 3vh;
  margin-left: 6vh;
}

.Service-item-images-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.Service-item-header-contianer {
  display: flex;
  flex-direction: row;
}

.Service-image-item-container {
  padding: 1vh;
}

.Service-image-item {
  object-fit: cover;
  height: 50vh;
  width: 100%;
  background-repeat: no-repeat;
}

.Service-icon {
  margin-right: 2vh;
}

#Item-0 {
  background-color: var(--primary);
  color: white;
}

.Reviews-container {
  display: flex;
  flex-direction: column;
}

.Reviews-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Reviews-header-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reviews-subheader {
  width: 80%;
  /* font-size: 7vh; */
  /* margin: 2vh; */
  text-align: left;
}

.Reviews-header-item {
  background-color: var(--primary);
  color: white;
  font-size: 6vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: bold;
  width: 100%;
  height: 12vh;
  margin-bottom: 2vh;
}

.Review-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Review-item-info-summary {
  width: 90%;
  text-align: left;
  font-size: 2.5vh;
  font-family: "Aleo", serif;
}

.Review-item-info-header {
  width: 90%;
  text-align: right;
  font-size: 3vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-style: italic;
}

@media (min-width: 600px) {
  .Review-item-info-summary {
    width: 80%;
    text-align: left;
    font-size: 3vh;
    font-family: "Aleo", serif;
  }

  .Review-item-info-header {
    width: 80%;
    text-align: right;
    font-size: 4vh;
    font-family: "Baloo Bhaina 2", cursive;
  }
}

:root {
  --primary: #123456;
}

.Contact-container {
  display: flex;
  flex-direction: column;
}

.Contact-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.Contact-header {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  font-size: 7vh;
  font-weight: bold;
  color: #123456;
  color: var(--primary);
  font-family: "Baloo Bhaina 2", cursive;
}

.Contact-body {
  width: 80%;
  font-size: 3vh;
  text-align: left;
  font-family: "Aleo", serif;
  margin-bottom: 2.5vh;
}

.Contact-body-bold {
  width: 80%;
  font-size: 3.5vh;
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: bold;
  text-align: left;
  height: 3vh;
}

@media (min-width: 600px) {
  .Contact-body {
    width: 60%;
    font-size: 3vh;
    text-align: left;
    font-family: "Aleo", serif;
    margin-bottom: 3vh;
  }

  .Contact-body-bold {
    width: 60%;
    font-size: 3.5vh;
    font-family: "Baloo Bhaina 2", cursive;
    font-weight: bold;
    text-align: left;
    height: 4vh;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

