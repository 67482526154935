@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

:root {
  --secondary: #123456;
}

#item1 {
  grid-area: item1;
}
#item2 {
  grid-area: item2;
}
#item3 {
  grid-area: item3;
}
#item4 {
  grid-area: item4;
}
#item5 {
  grid-area: item5;
}

.Header-links-container {
  display: grid;
  grid-template-areas:
    "item1 item2 item3"
    "item4 item4 item5";
  justify-content: space-around;
  align-items: center;
}

.Header-links-item {
  text-align: left;
  font-size: 2.5vh;
  color: white;
  font-weight: bolder;
  font-family: "Baloo Bhaina 2", cursive;
}

@media (min-width: 600px) {
  .Header-links-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8vh;
    height: 8vh;
  }

  .Header-links-item {
    margin-right: 2vh;
    font-size: 2.5vh;
    color: white;
    font-weight: bolder;
    font-family: "Baloo Bhaina 2", cursive;
  }
}
